import React from "react";
import img from "./images/logo2.svg";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function Footer() {
  const navigate = useNavigate();
  const ecom = () => {
    navigate("/ecommerce");
  };
  const mobile = () => {
    navigate("/mobileapp");
  };
  const ui = () => {
    navigate("/ui-ux");
  };
  const digital = () => {
    navigate("/digitalmarketing");
  };
  const gps = () => {
    window.open("http://expertsolutionsgps.com", "_blank");
  };
  const clallan = () => {
    window.open("https://tripchallanbooks.tripchallanbook.in/", "_blank");
  };
  return (
    <>
      <footer className="w-full flex flex-wrap p-6 m-auto justify-center ">
        <div className="w-full md:w-1/5  flex flex-col items-start m-1 p-2">
          <a href="/">
            <img src={img} className="w-48 h-20 mb-1" />
          </a>
          <p className="text-sm">
            At s-modality we have customized offerings for different domains.
            These solutions enable the users to manage and monitor their assets
            effectively and profitably, as per their requirements, thereby
            increasing the business efficiency.
          </p>
          <div className="flex mt-8">
            <a href="#">
              <FaFacebook className="text-2xl md:text-4xl mr-4 text-blue-800" />
            </a>
            <a href="#">
              <FaTwitter className="text-2xl md:text-4xl mr-4 text-indigo-900" />
            </a>
            <a href="#">
              <FaYoutube className="text-2xl md:text-4xl mr-4 text-red-600" />
            </a>
            <a href="#">
              <FaInstagram className="text-2xl md:text-4xl mr-4 text-pink-600" />
            </a>
          </div>
        </div>

        <div className="w-full md:w-1/5  m-1 mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Quick Links</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li>
              <a href="/" className="text-blue-700 font-bold">
                Home
              </a>
            </li>
            <li>
              <a href="/about" className="text-blue-700 font-bold">
                Company
              </a>
            </li>
            <li>
              <a href="/" className="text-blue-700 font-bold">
                Technology
              </a>
            </li>
            <li>
              <a href="/digitalmarketing" className="text-blue-700 font-bold">
                Services
              </a>
            </li>
            <li>
              <a href="/manufecture" className="text-blue-700 font-bold">
                Industries
              </a>
            </li>
            <li>
              <a href="/secommerce" className="text-blue-700 font-bold">
                Solutions
              </a>
            </li>
            <li>
              <a href="./contact" className="text-blue-700 font-bold">
                Contact Us
              </a>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1  mt-3 p-2 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-bold">Services</h2>
          <ul className="pt-4 text-lg space-y-2">
            <li
              className="text-blue-700 font-bold cursor-pointer"
              onClick={ecom}
            >
              Ecommerce
            </li>
            <li
              className="text-blue-700 font-bold cursor-pointer"
              onClick={mobile}
            >
              MobileApp
            </li>
            <li className="text-blue-700 font-bold cursor-pointer" onClick={ui}>
              UI/UX
            </li>
            <li
              className="text-blue-700 font-bold cursor-pointer"
              onClick={digital}
            >
              Digital Marketing
            </li>
            <li
              className="text-blue-700 font-bold cursor-pointer"
              onClick={gps}
            >
              Gps Tracking
            </li>
            <li
              className="text-blue-700 font-bold cursor-pointer"
              onClick={clallan}
            >
              challanbook
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/5  m-1 p-2 text-center">
          <h2 className="text-2xl md:text-3xl font-bold pb-4">Reach Us</h2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28110.92664481033!2d83.39607531042365!3d22.096240381161685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a273640cfd3cc05%3A0x51f4e6b88c2f3349!2sTamnar%2C%20Chhattisgarh%20496111!5e1!3m2!1sen!2sin!4v1729404622582!5m2!1sen!2sin"
            style={{ border: 0 }}
            className="w-full h-64"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </footer>

      <div className="p-2 bg-slate-700 text-center">
        <p className="text-lg text-white">
          Copyright ©{" "}
          <b>
            <a href="#" className="text-white">
              S-Modality
            </a>
          </b>{" "}
          2024. All Rights Reserved.
        </p>
      </div>
    </>
  );
}

import React from "react";
// import transportpage from '../images/s1.jpg'
import transportpage from "../images/transport.png";
import transportpage1 from "../images/m.jpg";
import transportpage2 from "../images/img7.png";
import transportpage3 from "../images/img8.jpg";
import transportpage4 from "../images/img9.png";
import transportpage5 from "../images/project.png";
import transportpage7 from "../images/TVSLogo.svg";
import transportpage6 from "../images/TVSMerchandise.png";
import transportpage8 from "../images/relaxo.png";
import transportpage9 from "../images/pvr.png";
import transportpage10 from "../images/skf.png";
import transportpage11 from "../images/hero.png";
import transportpage12 from "../images/eicher.png";
import transportpage13 from "../images/decthlon.png";
import transportpage14 from "../images/set.png";
import transportpage15 from "../images/javascript.png";
import transportpage16 from "../images/html.png";
import transportpage17 from "../images/tailwind.png";
import transportpage18 from "../images/nodejs.png";
import transportpage19 from "../images/express.png";
import transportpage20 from "../images/react.png";
import transportpage21 from "../images/mongodb.jpg";
import transportpage22 from "../images/sql.png";
import transec from "../images/trans1.jpg";
import ware from "../images/ware.jpg";
import tracking from "../images/tracking.jpg";
import logis from "../images/Logis.jpg";
import analyst from "../images/bi.jpg";
import technologypage15 from "../images/javascript.png";
import technologypage16 from "../images/html.png";
import technologypage17 from "../images/tailwind.png";
import technologypage18 from "../images/nodejs.png";
// import technologypage19 from "../images/express.png";
import technologypage20 from "../images/react.png";
import technologypage21 from "../images/mongodb.jpg";
import technologypage22 from "../images/sql.png";
import transban from "../images/transban.png";
import {
  FaIndustry,
  FaLightbulb,
  FaCog,
  FaArtstation,
  FaHandHoldingHeart,
  FaAccessibleIcon,
  FaAccusoft,
  FaAngellist,
  FaCheck,
  FaReact,
  FaHtml5,
  FaJava,
  FaAngular,
  FaVuejs,
} from "react-icons/fa";
const Card = ({ icon, title, description }) => (
  <div className="bg-white shadow-md rounded-lg p-6">
    <div className="flex items-center mb-4">
      <div className="text-purple-600 text-3xl mr-4">{icon}</div>
      <h2 className="text-xl font-semibold">{title}</h2>
    </div>
    <p className="text-gray-600">{description}</p>
  </div>
);

export default function transport() {
  const serviceData = [
    {
      icon: "🚗",
      title: "Connected & Internet Cars",
      description:
        "Our transportation software services drive connected cars with a specialized IoT system with integrated features of vehicle diagnostics, fuel efficiency, and enhanced safety for user satisfaction from the automotive services.",
    },
    {
      icon: "🔌",
      title: "EV Charging Software",
      description:
        "You will get advanced and smart white-label EV charging software solutions. These IT services for the transportation industry empower vehicles to unlock hidden opportunities for the next-gen automotive transportation industry.",
    },
    {
      icon: "🚲",
      title: "Smart Bikes",
      description:
        "Get the robust all-in-one rider information systems for your transportation services. So, you can offer simplicity, safety, and reliability to two-wheeler navigation. Plus, you can get a seamless and smart experience to the user and the prospects.",
    },
    {
      icon: "📡",
      title: "Telematics",
      description:
        "Innovative and tech-forward telematics solutions to enhance the connected network of users. And helps to empower brands to track key parameters such as vehicle condition, fuel usage, insurance details, and traffic for better optimization.",
    },
    {
      icon: "🤖",
      title: "Autonomous & ADAS",
      description:
        "We ensure to offer advanced applications to prospects and users. It will help to adapt, automate, and enhance the in-vehicle systems to enable autonomous and assisted driving to help your users in transportation.",
    },
    {
      icon: "🧠",
      title: "AI & Machine Learning",
      description:
        "Smart automotive solutions help in recognizing and analyzing the patterns of human behavior for future uses. It enables data-driven decisions to assist different situations to the users with the best next-gen solutions for the industry.",
    },
  ];
  return (
    <>
      <div className="bg-indigo-950 p-44 relative flex flex-col items-center justify-center h-full">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20"
          style={{ backgroundImage: `url(${transportpage})` }}
        />

        <h1 className="text-white text-5xl font-bold relative z-10 text-center m-2 p-2">
          Transportation{" "}
          <span className="text-orange-500 font-bold">Software</span> Services
        </h1>
        <ul className="flex flex-col sm:flex-row gap-4 text-center relative z-10 mt-4">
          <li className="text-white border border-t-gray-50 p-2 rounded-full flex items-center justify-center text-sm sm:text-base md:text-lg">
            <FaLightbulb className="mr-2 text-xl sm:text-2xl md:text-3xl" />
            Streamline Logistic Process
          </li>
          <li className="text-white border border-t-gray-50 p-2 rounded-full flex items-center justify-center text-sm sm:text-base md:text-lg">
            <FaCog className="mr-2 text-xl sm:text-2xl md:text-3xl" /> Quality
            Transportation Solution
          </li>
        </ul>

        <div className="text-center relative z-10 mt-6">
          <button>
            <a
              href="/contact"
              className="text-white bg-orange-500 rounded-full px-6 py-2 text-sm sm:text-base md:text-lg"
            >
              Request A Quote
            </a>
          </button>
        </div>
      </div>

      <div>
        <h2 className="flex justify-center font-bold m-5 text-4xl">
          Our next-gen automotive Expertise{" "}
        </h2>
        <div className="max-w-7xl mx-auto p-4">
          <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {serviceData.map((service, index) => (
              <Card
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div>
        </div>

        <div className="bg-indigo-800 relative">
          <div className="text-center grid grid-cols-1 md:grid-cols-2 gap-2 container">
            <div className="mt-5 p-5 bg-indigo-800 ">
              <h3 className="text-white text-4xl">
                Get The tech-driven IT service For The Transportation History
              </h3>
              <p className="text-slate-300">
                At OrangeMantra, we believe that smooth operations and effective
                management are fundamental to a successful retail business. Our{" "}
                <strong>Retail Management System services</strong> are designed
                to empower your company.
              </p>
              <ul className="flex flex-wrap items-center">
                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Efficient Inventory Management</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Seamless Multichannel Integration</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>CRM Features</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Analytics and Business Intelligence</span>
                </li>
              </ul>
            </div>
            <img
              src={transec}
              className="rounded  w-full relative z-150 h-full items-center"
            />
          </div>
        </div>

        <div className="container mb-6">
          <h2 className="text-center m-5">
            Our Value Driven Transportaion Software Solution take business to
            next level
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img src={ware} className="w-full rounded-t-lg h-[250px]" />
              <h5 className="text-black p-2">Warehouse management Software</h5>
              <p className="p-2">
                Our manufacturing IT solutions enhance production efficiency and
                quality control in the automotive industry.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img src={logis} className="w-full rounded-t-lg h-[250px]" />
              <h5 className="text-black p-2">Logistic visibility Software</h5>
              <p className="p-2">
                Manufacturing IT solutions optimize food safety and production
                efficiency while adhering to regulations.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img src={tracking} className="w-full rounded-t-lg h-[250px]" />
              <h5 className="text-black p-2">End to End Tracking Services</h5>
              <p className="p-2">
                Software developing services ensure regulatory compliance and
                quality control.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img src={analyst} className="w-full rounded-t-lg h-[250px]" />
              <h5 className="text-black p-2">Business Intelligence Analysis</h5>
              <p className="p-2">
                Streamline complex manufacturing processes and manage supply
                chains.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-indigo-950 relative   h-full p-10">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-50 "
          style={{
            backgroundImage: `url(${transban})`,
            backgroundColor: "rgba(0,0,0,0.6)",
            backgroundBlendMode: "multiply",
          }}
        />
        <h1 className="text-white text-5xl font-bold relative z-10 text-center m-2 p-2">
          Want to Streamline Your{" "}
          <span className="text-orange-500 font-bold">
            Transportation Business Processes
          </span>{" "}
          to gain Profit ?
        </h1>

        <div className="text-center relative z-10">
          <a
            href="/contact"
            className=" text-white  bg-orange-600 hover:bg-orange-500  rounded-full p-2 inline-block"
          >
            Get Started
          </a>
        </div>
      </div>

      <div className="mt-5 container">
        <h3 className="text-center text-4xl font-bold">Our Tech stack</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 m-5">
          <div className="border border-white shadow rounded-xl ">
            <h4 className="text-white bg-indigo-800 p-2 rounded-t-xl">
              Programming Languages
            </h4>
            <ul className="space-y-2 p-0 px-2">
              <li className="flex items-center">
                <img
                  src={technologypage15}
                  className="w-6 h-6 mr-2"
                  alt="Javascript"
                />
                Javascript
              </li>
              <li className="flex items-center">
                <img
                  src={technologypage16}
                  className="w-6 h-6 mr-2"
                  alt="HTML/CSS"
                />
                Html/CSS
              </li>
              <li className="flex items-center">
                <img
                  src={technologypage17}
                  className="w-6 h-6 mr-2"
                  alt="Tailwind CSS"
                />
                Tailwind CSS
              </li>
              <li className="flex items-center">
                <img
                  src={technologypage18}
                  className="w-6 h-6 mr-2"
                  alt="Node JS"
                />
                Node JS
              </li>
            </ul>
          </div>
          <div className="border border-white shadow rounded-xl ">
            <h4 className="text-white bg-indigo-800 p-2 rounded-t-xl">
              Frameworks
            </h4>
            <ul className="space-y-2 p-0 px-2">
              <li className="flex items-center">
                <img
                  src={technologypage20}
                  className="w-6 h-6 mr-2"
                  alt="React JS"
                />
                React JS
              </li>
              <li className="flex items-center">
                <FaAngular className="w-6 h-6 mr-2" />
                Angular
              </li>
              <li className="flex items-center">
                <FaVuejs className="w-6 h-6 mr-2" />
                Vue
              </li>
              <li className="flex items-center">
                <span className="w-6 h-6 mr-2 font-bold text-gray-500 text-xl">
                  eX
                </span>
                Express
              </li>
            </ul>
          </div>
          <div className="border border-white shadow rounded-xl ">
            <h4 className="text-white bg-indigo-800 p-2 rounded-t-xl">
              Database
            </h4>
            <ul className="space-y-2 p-0 px-2">
              <li className="flex items-center">
                <img
                  src={technologypage21}
                  className="w-6 h-6 mr-2"
                  alt="MongoDB"
                />
                MongoDB
              </li>
              <li className="flex items-center">
                <img
                  src={technologypage22}
                  className="w-6 h-6 mr-2"
                  alt="SQL"
                />
                SQL
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

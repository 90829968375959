import React from "react";
import retailpage from "../images/retail.png";
import retailpage1 from "../images/m.jpg";
import retailpage2 from "../images/img7.png";
import retailpage3 from "../images/img8.jpg";
import retailpage4 from "../images/img9.png";
import retailpage5 from "../images/project.png";
import retailpage7 from "../images/TVSLogo.svg";
import retailpage6 from "../images/TVSMerchandise.png";
import retailpage8 from "../images/relaxo.png";
import retailpage9 from "../images/pvr.png";
import retailpage10 from "../images/skf.png";
import retailpage11 from "../images/hero.png";
import retailpage12 from "../images/eicher.png";
import retailpage13 from "../images/decthlon.png";
import retailpage14 from "../images/set.png";
import retailpage15 from "../images/javascript.png";
import retailpage16 from "../images/html.png";
import retailpage17 from "../images/tailwind.png";
import retailpage18 from "../images/nodejs.png";
import retailpage19 from "../images/express.png";
import retailpage20 from "../images/react.png";
import retailpage21 from "../images/mongodb.jpg";
import retailpage22 from "../images/sql.png";
import {
  FaIndustry,
  FaCog,
  FaArtstation,
  FaHandHoldingHeart,
  FaAccessibleIcon,
  FaAccusoft,
  FaAngellist,
  FaCheck,
  FaChargingStation,
  FaInternetExplorer,
  FaIcons,
  FaUserFriends,
  FaInfo,
  FaInnosoft,
  FaWarehouse,
  FaTimes,
  FaTable,
  FaDigitalOcean,
} from "react-icons/fa";
import { FaFreeCodeCamp, FaInvision, FaMobile } from "react-icons/fa6";
export default function retail() {
  return (
    <>
      <div className="bg-indigo-950 p-10 sm:p-20 lg:p-44 relative flex flex-col items-center justify-center h-full border overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center opacity-20"
          style={{ backgroundImage: `url(${retailpage})` }}
        />

        <h1 className="text-white text-3xl sm:text-4xl lg:text-5xl font-bold relative z-10 text-center m-2 p-2">
          Retail Management Software Solutions to Strengthen Your Company
        </h1>

        <ul className="flex flex-col sm:flex-row gap-4 text-center relative w-auto">
          <li className="group text-white border border-t-gray-50 px-3 py-3 h-14 rounded-full flex items-center w-auto text-lg font-semibold hover:bg-white hover:text-black transition duration-300">
            <FaIcons className="text-2xl fill-white sm:text-3xl lg:text-4xl mr-2 group-hover:fill-black" />
            <span className="group-hover:text-black leading-5">
              Multichannel Integration
            </span>
          </li>

          <li className="group text-white border border-t-gray-50 px-3 py-3 h-14 rounded-full flex items-center w-auto text-lg font-semibold hover:bg-white hover:text-black transition duration-300">
            <FaUserFriends className="text-2xl fill-white sm:text-3xl lg:text-4xl mr-2 sm:m-0 group-hover:fill-black" />
            <span className="group-hover:text-black leading-4">
              User-Friendly Interface and Accessibility
            </span>
          </li>
        </ul>

        <div className="text-center relative z-10 flex flex-col sm:flex-row">
          <button>
            <a
              href=""
              className="text-white bg-orange-500 rounded-full p-2 hover:bg-orange-600 transition duration-300"
            >
              Request A Quote
            </a>
          </button>
        </div>
      </div>

      <div className="container">
        <h2 className="flex justify-center text-3xl md:text-5xl font-bold text-center mx-5 mt-7">
          Retail Management Software Solutions: Empowering Retail Excellence,
          Every Step of the Way
        </h2>
        <p className="text-center text-lg md:text-xl mx-5 p-5">
          OrangeMantra is aware of the difficulties that retail establishments
          must overcome. With a range of all-inclusive solutions to improve
          every facet of your retail operations, our{" "}
          <strong>Store Management Software</strong> services are built to
          tackle these issues head-on.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 m-5">
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaInnosoft className="text-3xl" />
              Inventory Management
            </h3>
            <p>
              Effectively oversee and regulate your stock with accuracy. Our
              Retail inventory management software guarantees real-time
              visibility, reducing the likelihood of stockouts and overstocks
              and maximizing order fulfillment.
            </p>
          </div>
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaWarehouse className="text-3xl" />
              Warehouses Management{" "}
            </h3>
            <p>
              Optimize the efficiency of your warehouse operations by
              streamlining them. Our warehouse management system assists in
              planning, monitoring, and optimizing warehouse operations.
            </p>
          </div>
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaTable className="text-3xl" />
              Scheduling of Production
            </h3>
            <p>
              Use our manufacturing Planning module to streamline your
              manufacturing procedures. Arrange, plan, and oversee production
              processes for the best use of available resources.
            </p>
          </div>
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaFreeCodeCamp className="text-3xl" />
              Forecasting Demand
            </h3>
            <p>
              With the help of our demand forecasting tools, we can accurately
              predict market demand. Utilize data analytics to forecast trends
              and consumer preferences.
            </p>
          </div>
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaDigitalOcean className="text-3xl" />
              Engagement of Digital Customers
            </h3>
            <p>
              With our digital customer engagement solutions, improve customer
              interactions. Construct individualized experiences and cultivate
              client loyalty.
            </p>
          </div>
          <div className="border border-white shadow m-3 p-3 rounded">
            <h3 className="text-indigo-900 text-lg">
              <FaMobile className="text-3xl" />
              Mobile Apps for Retail
            </h3>
            <p>
              With our retail mobile apps, stay in touch with your consumers
              while on the road. Increase consumer satisfaction with tailored
              offers.
            </p>
          </div>
        </div>

        <div className="bg-indigo-800 relative">
          <div className="text-center grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="mt-5 p-5 bg-indigo-800">
              <h3 className="text-white">
                Our Retail Management System Services to Boost Your Retail
                Experience
              </h3>
              <p className="text-slate-300">
                At OrangeMantra, we believe that smooth operations and effective
                management are fundamental to a successful retail business. Our{" "}
                <strong>Retail Management System services</strong> are designed
                to empower your company.
              </p>
              <ul className="flex flex-wrap items-center">
                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Efficient Inventory Management</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Seamless Multichannel Integration</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>CRM Features</span>
                </li>

                <li className="flex items-center p-3 m-2 border rounded-full w-auto bg-white">
                  <FaCheck className="mr-2 text-green-500" />
                  <span>Analytics and Business Intelligence</span>
                </li>
              </ul>
            </div>
            <img
              src={retailpage1}
              className="rounded  w-[400px] relative m-5 z-150 h-[400px] items-center"
            />
          </div>
        </div>

        <div className="container mb-6">
          <h2 className="text-center m-5">
            Tailored IT Solutions for Manufacturing Industry
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img
                src={retailpage2}
                className="w-full rounded-t-lg h-[250px]"
              />
              <h5 className="text-black p-2">Automotive Manufacturing</h5>
              <p className="p-2">
                Our manufacturing IT solutions enhance production efficiency and
                quality control in the automotive industry.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img
                src={retailpage3}
                className="w-full rounded-t-lg h-[250px]"
              />
              <h5 className="text-black p-2">Food & Beverage Manufacturing</h5>
              <p className="p-2">
                Manufacturing IT solutions optimize food safety and production
                efficiency while adhering to regulations.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img
                src={retailpage4}
                className="w-full rounded-t-lg h-[250px]"
              />
              <h5 className="text-black p-2">Pharmaceutical Manufacturing</h5>
              <p className="p-2">
                Software developing services ensure regulatory compliance and
                quality control.
              </p>
            </div>
            <div className="rounded-xl bg-slate-100 shadow-lg">
              <img
                src={retailpage5}
                className="w-full rounded-t-lg h-[250px]"
              />
              <h5 className="text-black p-2">Aerospace and Defence</h5>
              <p className="p-2">
                Streamline complex manufacturing processes and manage supply
                chains.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { GrCertificate } from "react-icons/gr";
import { FaThumbsUp } from "react-icons/fa";
import { BiHappyBeaming } from "react-icons/bi";
import { RiMailSettingsFill } from "react-icons/ri";
import pic from "../images/card3.png";

export default function Ecommerce() {
  return (
    <div className="w-full">
      <section
        className="w-full bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 49, 0.8), rgb(90, 3, 56,0.8)), url(${pic})`,
        }}
      >
        <div className="flex flex-col max-w-[1440px]  md:flex-row items-center justify-center mx-auto p-8 space-x-8 ">
          <div className="text-white w-full ">
            <h1 className="font-bold text-5xl mb-6">
              Ecommerce Website Development That Matches Your Needs
            </h1>

            <ul className="flex flex-wrap mt-3 p-0 gap-2">
              <li className="  px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center">
                SEO
              </li>
              <li className=" px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center ">
                PPC
              </li>
              <li className=" px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center ">
                SMO
              </li>
              <li className=" px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center ">
                ORM
              </li>
              <li className=" px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center ">
                Email Marketing
              </li>
              <li className=" px-3 py-2 border rounded-full font-bold mr-2 flex items-center justify-center ">
                Google Analytics
              </li>
            </ul>
          </div>
          <div className=" w-full  mx-0 my-16">
            <h1 className="mb-4 text-2xl">Contact</h1>
            <div className="max-w-[450px] mx-auto bg-[#ffffff] flex flex-col items-center justify-center  pb-4 rounded-lg overflow-hidden">
              <h1 className="mb-4 text-2xl bg-gradient-to-r from-[#000031] to-[#590338] w-full text-center text-white p-4">
                Contact Us
              </h1>
              <div className="w-full px-3">
                <form className="w-full flex flex-col items-center justify-center">
                  <input
                    className="block w-full px-3 py-2 bg-[#f4f4f4] mb-2 rounded-lg"
                    placeholder="Full Name*"
                  />
                  <input
                    className="block w-full px-3 py-2 bg-[#f4f4f4] mb-2 rounded-lg"
                    placeholder="Business Email Address*"
                  />
                  <input
                    className="block w-full px-3 py-2 bg-[#f4f4f4] mb-2 rounded-lg"
                    placeholder="Full Name*"
                  />
                  <input
                    className="block w-full px-3 py-2 bg-[#f4f4f4] mb-2 rounded-lg"
                    placeholder="Phone Number*"
                  />
                  <input
                    className="block w-full px-3 py-2 bg-[#f4f4f4]  rounded-md"
                    placeholder="messages*"
                  />
                  <button className="w-full px-6 py-2 mt-10 bg-gradient-to-r from-[#000031] to-[#590338] text-white rounded-full">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full bg-[#ffff] flex  p-8 ">
        <div className="flex flex-col md:flex-row items-center justify-center max-w-[1280px] mx-auto ">
          <div className="w-full md:w-1/2">
            <h1 className="text-4xl font-semibold mb-4 ">
              {" "}
              Stay Digitally Competitive with Reliable eCommerce Website
              Development Services{" "}
            </h1>
            <p className="text-base font-normal ">
              {" "}
              The secret to winning the e-commerce game lies in having an online
              presence that matches the expectations of your audience and
              compels them to convert. And it calls for eCommerce website
              development expertise laser-focused on UX. To attract the desired
              audience, you need an e-commerce store that is visually stunning
              and conversion-focused.
            </p>
            <p className="text-base font-normal ">
              {" "}
              We not only assist businesses in creating e-commerce solutions
              that give them a solid foundation but also ensure that they run
              seamlessly through their evolution.
            </p>
          </div>
          <div className="w-full md:w-1/2 flex flex-wrap items-center justify-center gap-2">
            <div className=" h-[180px] w-[250px] border rounded-xl flex flex-col p-4 ">
              <GrCertificate className=" text-5xl text-white p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg" />
              <h1 className="  text-5xl  font-bold text-[000031]">15+</h1>
              <span className="text-base font-semibold">years in market</span>
            </div>
            <div className=" h-[180px] w-[250px] border rounded-xl flex flex-col p-4 ">
              <FaThumbsUp className="text-5xl text-white p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg" />
              <h1 className="text-xl sm:text-3xl lg:text-5xl font-bold text-[000031]">
                250+
              </h1>
              <span className="text-base font-semibold">
                eCommerce Specialists
              </span>
            </div>
            <div className=" h-[180px] w-[250px] border rounded-xl flex flex-col p-4 ">
              <RiMailSettingsFill className="text-5xl text-white p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg" />
              <h1 className="text-5xl font-bold text-[000031]">90%</h1>
              <span className="text-base font-semibold">Customers NPS</span>
            </div>
            <div className=" h-[180px] w-[250px] border rounded-xl flex flex-col p-4 ">
              <BiHappyBeaming className="text-5xl text-white p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg" />
              <h1 className="text-5xl font-bold text-[000031]">400+</h1>
              <span className="text-base font-semibold">Projects Launched</span>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full bg-gray-50 py-4 ">
        <div className="w-full max-w-[1440px] mx-auto p-8">
          <h1 className="text-center text-4xl font-semibold mb-10">
            eCommerce Development Platform We Use
          </h1>
          <p className="text-center text-base font-medium text-gray-700 mb-12">
            As your value-driven digital marketing agency in India, we are here
            to meet all your brand’s advertising needs with our expert-level
            services. Our extensive services for your digital marketing website
            will allow you to grow your business immensely.{" "}
          </p>
          <div className="w-full flex flex-wrap  justify-center items-center gap-4 p-8">
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
            <div className="group  w-full md:w-1/3 lg:w-1/4 border flex flex-col p-4 rounded-tl-3xl rounded-br-3xl cursor-pointer shadow-xl transition-all duration-300 ease-out hover:bg-gradient-to-r from-[#000031] to-[#7f0f54] hover:rounded-tl-none hover:rounded-br-none hover:rounded-tr-3xl hover:rounded-bl-3xl">
              <BiHappyBeaming className=" text-white mx-auto  text-5xl fill-white  p-2 bg-gradient-to-r from-[#000031] to-[#7f0f54] rounded-lg transition-all group-hover:bg-gradient-to-r group-hover:from-white group-hover:to-white  group-hover:fill-[#3e1572]" />
              <h1 className="text-2xl font-semibold mb-3 text-center group-hover:text-white">
                Cloud & Headless Commerce{" "}
              </h1>
              <p className="text-base text-center font-medium text-gray-600 group-hover:text-white">
                Highly scalable, secure, and super fast cloud-powered eCommerce
                solutions that provide your customer with immersive services.
              </p>
              {/* <a  href="/services/search-engine-optimization/"  className='text-orange-600 group-hover:text-white text-center'>Read More</a>    */}
            </div>
          </div>
        </div>
      </section>
      <section className="w-full p-4"></section>
    </div>
  );
}
